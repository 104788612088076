<template>
	<div v-if="location.value">
		<BackButton :route="{ name: 'md-staff-locations', label: 'Locations' }" />

		<div class="md:flex md:items-center md:justify-between my-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Location:
					<span class="text-sc-green">{{ location.value.name || '' }}</span>
				</h2>
			</div>
			<div v-if="draft && page" class="ml-4 mt-2 flex-shrink-0 flex gap-x-5">
				<a
					:href="frontendUrl + draft.path + '?preview=' + draft.preview_key"
					target="_blank"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none hover:cursor-pointer"
				>
					<ExternalLinkIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Preview Draft
				</a>

				<a
					:href="frontendUrl + page.path"
					target="_blank"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none hover:cursor-pointer"
					:class="[page.live ? '' : 'opacity-50 pointer-events-none']"
				>
					<ExternalLinkIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					View Page
				</a>
			</div>
		</div>

		<div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
			<aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
				<nav class="space-y-1">
					<button
						v-for="(item, index) in navigation"
						:key="item.name"
						:class="[
							item.current
								? 'bg-gray-200 text-sc-blue hover:text-sc-blue hover:bg-gray-200'
								: 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
							'group min-w-full rounded-md px-3 py-2 flex items-center text-sm font-medium focus:outline-none',
						]"
						:aria-current="item.current ? 'page' : undefined"
						@click.prevent="changeTab(index)"
					>
						<component
							:is="item.icon"
							:class="[
								item.current
									? 'text-sc-blue group-hover:text-sc-blue'
									: 'text-gray-400 group-hover:text-gray-500',
								'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
							]"
							aria-hidden="true"
						/>
						<span class="truncate">
							{{ item.name }}
						</span>
					</button>
				</nav>
			</aside>

			<div class="sm:px-6 lg:px-0 lg:col-span-10">
				<MdStaffLocationGeneralInformation v-if="location && currentTab == 0" @updatedPage="fetchPage" />
				<MdStaffLocationGallery v-if="location && currentTab == 1" />
				<MdStaffLocationHours v-if="location && currentTab == 2" />
				<MdStaffLocationAmenities v-if="location && currentTab == 3" />
				<MdStaffLocationProvidersAndSpecialties v-if="location && currentTab == 4" />
			</div>
		</div>
	</div>
</template>

<script>
import { provide, reactive, computed, ref, inject } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'
import getEnv from '@/extensions/env'

import {
	ViewListIcon,
	UsersIcon,
	OfficeBuildingIcon,
	InformationCircleIcon,
	ExternalLinkIcon,
	PhotographIcon,
	SearchCircleIcon,
	ClockIcon,
	LibraryIcon,
	// DatabaseIcon,
} from '@heroicons/vue/outline'

export default {
	components: {
		ExternalLinkIcon,
		// DatabaseIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		const location = reactive({})

		const page = ref(null)
		const draft = ref(null)
		const original = ref(null)

		provide(
			'location',
			computed(() => location.value)
		)

		return {
			page,
			draft,
			original,
			api,
			toast,
			route,
			location,
		}
	},
	data() {
		return {
			id: this.$route.params.id,
			record: {},
			currentTab: 0,
			navigation: [
				{
					name: 'General Information',
					icon: OfficeBuildingIcon,
					current: true,
				},
				{
					name: 'Gallery',
					icon: PhotographIcon,
					current: false,
				},
				{
					name: 'Hours',
					icon: ClockIcon,
					current: false,
				},
				{
					name: 'Ameneties',
					icon: LibraryIcon,
					current: false,
				},
				{
					name: 'Providers & Specialties',
					icon: UsersIcon,
					current: false,
				},
				// {
				//   name: "Videos",
				//   icon: FilmIcon,
				//   current: false,
				// },
			],
			// page: null,
			frontendUrl: getEnv('VUE_APP_FRONTEND_URL'),
		}
	},
	mounted() {
		this.fetchLocation()
		this.fetchPage()
	},
	methods: {
		fetchLocation() {
			this.loaderShow()

			this.api.locations
				.fetch(this.id)
				.then(data => {
					this.location.value = data
				})
				.catch(error => {
					this.toast.error('Error fetching location:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		setPage(page) {
			this.draft = null
			this.page = null
			this.original = null

			setTimeout(() => {
				if (page.draft_page) {
					this.draft = page.draft_page
					this.original = JSON.stringify(this.draft)
					this.page = JSON.parse(JSON.stringify(page))
					delete this.page.page_draft
				} else {
					this.draft = page
					this.original = JSON.stringify(this.draft)
					this.page = JSON.parse(JSON.stringify(page))
				}

				// console.log(this.draft)
			}, 100)
		},

		fetchPage() {
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.pages.fetch()
				.then(page => {
					this.setPage(page)
					// if (Object.keys(page).length !== 0) this.page = page
				})
				.then(() => {
					this.loaderHide()
				})
		},

		cancelEditing() {
			this.fetchLocation()
		},

		changeTab(index) {
			this.navigation.forEach(element => {
				element.current = false
			})

			this.navigation[index].current = true
			this.currentTab = index
		},
	},
}
</script>
